import React from 'react'
//Dont import design system here, this is loaded first to get something on screen. the rest of the app is loaded in after

const SplashScreen = ({ message }) => {
    return (
        <div style={{ position: "absolute", display: "flex", top: 0, left: 0, right: 0, bottom: 0 }}>
            <div style={{ margin: "auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
                {/* Inline due to an issue with Chrome reloading the svg when styling is loaded, causing a flashing effect */}
                <svg width="126" height="47" viewBox="0 0 126 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10.4005H28.686V16.8886H6.552C2.94 16.8886 0 13.9772 0 10.4005Z" fill="#204D63" />
                    <path d="M9.57579 20.2574H28.7278V26.7455H16.1278C12.4738 26.7455 9.53379 23.8342 9.57579 20.2574Z" fill="#204D63" />
                    <path d="M19.1519 30.1559H28.7279V36.644H25.7039C22.0919 36.644 19.1519 33.7327 19.1519 30.1559Z" fill="#204D63" />
                    <path d="M32.046 47V10.4005H38.598V12.9375C40.698 11.2739 43.344 10.3589 46.032 10.4005C52.962 10.4005 58.506 16.1399 58.506 23.543C58.548 26.7871 57.414 29.9064 55.314 32.3602C52.878 35.0636 49.35 36.644 45.654 36.6024C43.176 36.644 40.782 35.8538 38.85 34.3149V46.9584H32.046V47ZM38.85 19.7583V27.3278C40.278 29.2825 42.546 30.447 44.982 30.447C48.846 30.0727 51.66 26.7039 51.282 22.8776C50.946 19.6335 48.426 17.0549 45.15 16.6806C42.672 16.6806 40.362 17.8036 38.892 19.7583H38.85Z" fill="#204D63" />
                    <path d="M87.2761 10.3588V36.6024H80.7241V34.0654C78.7921 35.729 76.2721 36.6024 73.7101 36.6024C70.0561 36.6024 66.5701 35.0635 64.1341 32.3602C62.0341 29.9063 60.8581 26.7455 60.9001 23.543C60.9001 16.0983 66.4861 10.4004 73.3741 10.4004C76.0621 10.3588 78.7081 11.2738 80.8081 12.9374V10.4004H87.2761V10.3588ZM80.4301 27.3277V19.7999C78.9601 17.8451 76.6081 16.6806 74.1301 16.6806C70.3081 17.1381 67.5361 20.5901 67.9981 24.3748C68.3761 27.6188 70.9801 30.1143 74.2561 30.447C76.7341 30.447 79.0441 29.2825 80.4301 27.3277Z" fill="#204D63" />
                    <path d="M96.9782 10.4005H90.1742V36.644H96.9782V10.4005Z" fill="#204D63" />
                    <path d="M125.916 36.644H119.364V34.107C117.432 35.7706 114.912 36.644 112.35 36.644C108.696 36.644 105.21 35.1052 102.774 32.4018C100.674 29.9479 99.4979 26.7871 99.5399 23.5846C99.5399 16.1399 105.126 10.442 112.014 10.442C114.618 10.3589 117.138 11.1907 119.196 12.7711V0.0860291H126L125.916 36.644ZM119.112 27.3277V19.7999C117.642 17.8451 115.29 16.6806 112.812 16.6806C108.99 17.1381 106.218 20.5901 106.68 24.3748C107.058 27.6189 109.662 30.1143 112.938 30.447C115.416 30.447 117.726 29.2825 119.112 27.3277Z" fill="#204D63" />
                    <path d="M89.4602 3.99553C89.3762 6.28301 91.1822 8.23775 93.4922 8.32093C95.8022 8.40411 97.7762 6.61572 97.8602 4.32825C97.9442 2.04078 96.1382 0.0860328 93.8282 0.00285203C93.7862 0.00285203 93.7442 0.00285203 93.6602 0.00285203C91.4342 -0.0803288 89.5442 1.66647 89.4602 3.87076C89.4602 3.91235 89.4602 3.95394 89.4602 3.99553Z" fill="#204D63" />
                </svg>
                {
                    !!message &&
                    <span style={{ display: "block", marginTop: "16px", fontFamily: "Karla" }}>{message}</span>
                }
            </div>
        </div>
    )
}

export default SplashScreen